


































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Vue } from 'vue-property-decorator'
import { getDicts } from '@/utils/dict'
import {
  Select,
  Option,
  DatePicker,
  Checkbox,
  Upload,
  Autocomplete,
  Radio,
  RadioGroup
} from 'element-ui'
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Checkbox)
Vue.use(Upload)
Vue.use(Autocomplete)
Vue.use(Radio)
Vue.use(RadioGroup)

// const greenDefault = require('@/assets/images/map_green_default.png')
// const greenChoosed = require('@/assets/images/map_green_choosed.png')

@Component
export default class extends Vue {
  // 字典数据
  // 设备类型
  equipDicts: any = []
  // 所有设备组
  allEquipGroup = []
  // 设备方向
  directionDicts = [] as any
  islong = false
  latlng = {
    lng: '120.626999',
    lat: '31.332664'
  }
  equipInfo = {
    groupId: '',
    direction: '1',
    type: '',
    remark: '',
    model: '',
    deviceName: '',
    ip: '',
    port: '',
    username: '',
    password: '',
    streamUrl: ''
  }
  equipInfoRules = {
    groupId: [{ required: true, message: '请选择设备组', trigger: 'change' }],
    type: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
    direction: [
      {
        required: true,
        message: '请选择设备方向',
        trigger: 'change'
      }
    ],
    remark: [{ max: 200, message: '长度在200个字符', trigger: 'blur' }],
    model: [
      { required: true, message: '请填写设备型号', trigger: 'blur' },
      { max: 50, message: '长度在50个字符', trigger: 'blur' }
    ],
    deviceName: [
      { required: true, message: '请填写设备名称', trigger: 'blur' },
      { max: 50, message: '长度在50个字符', trigger: 'blur' }
    ],
    ip: [
      { required: true, message: '请填写设备IP', trigger: 'blur' },
      {
        pattern: /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/,
        message: '请填写正确填写设备IP',
        trigger: 'blur'
      }
    ],
    port: [
      { required: true, message: '请填写设备端口', trigger: 'blur' },
      {
        pattern: /^[0-9]+$/,
        message: '请正确输入端口号',
        trigger: 'blur'
      },
      { validator: this.validatorPort, trigger: 'blur' }
    ],
    username: [
      { required: true, message: '请填写用户名', trigger: 'blur' },
      { max: 20, message: '长度在20个字符以内', trigger: 'blur' },
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '请输入英文或数字',
        trigger: 'blur'
      }
    ],
    password: [
      { required: true, message: '请填写密码', trigger: 'blur' },
      { max: 20, message: '长度在20个字符以内', trigger: 'blur' },
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '请输入英文或数字',
        trigger: 'blur'
      }
    ]
  }
  isShowDialog = false
  loading = false

  validatorPort(rule: any, value: any, callback: any) {
    if (value < 1 || value > 65535) {
      callback(new Error('在1-65535之间'))
    } else {
      callback()
    }
  }

  async beforeCreate() {
    // 获取相关字典
    // 设备类型
    this.equipDicts = await getDicts('DEVICE_TYPE')
    this.directionDicts = await getDicts('CAR_BRAKE_DIRECTION')
    this.getAllEquipGroup()
    if (this.$route.query.id) {
      this.getEquipDetail()
    } else {
      this.baiduMap()
    }
  }

  // 获取所有设备组
  getAllEquipGroup() {
    this.$api.equipment.getDeviceGroups({ size: '-1' }).then((res: any) => {
      const data = res.data
      if (data.success) {
        this.allEquipGroup = data.data
      }
    })
  }

  changeType() {
    if (this.equipInfo.direction !== '1' && this.equipInfo.direction !== '2') {
      this.equipInfo.direction = ''
    }
  }

  // 获取设备详情
  getEquipDetail() {
    this.$api.equipment
      .getDeviceDetail(Number(this.$route.query.id))
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          const info = data.data
          this.baiduMap(info.lat, info.lng)
          this.equipInfo = {
            groupId: info.deviceGroupId,
            type: info.type + '',
            direction: info.direction + '',
            remark: info.remark,
            model: info.model,
            deviceName: info.deviceName,
            ip: info.ip,
            port: info.port,
            username: info.username,
            password: info.password,
            streamUrl: info.streamUrl
          }
          this.$nextTick(() => {
            ;(document.getElementById('latlng') as any).value =
              info.lng + ', ' + info.lat
          })
        }
      })
  }

  // 保存
  save() {
    if (
      (this.equipInfo.type === '1' || this.equipInfo.type === '2') &&
      !this.equipInfo.direction
    ) {
      this.$message.warning('请选择设备方向')
    }
    if (this.equipInfo.type === '3' || this.equipInfo.type === '4') {
      this.equipInfo.direction = ''
    }
    ;(this.$refs['equipInfo'] as any).validate((valid: any) => {
      if (valid) {
        this.loading = true
        if (this.$route.query.id) {
          // 编辑设备
          this.$api.equipment
            .editEquip({
              id: Number(this.$route.query.id),
              ...this.equipInfo,
              type: Number(this.equipInfo.type),
              lng: Number(this.latlng.lng),
              lat: Number(this.latlng.lat)
            })
            .then((res: any) => {
              this.loading = false
              const data = res.data
              if (data.success) {
                this.$message.success('编辑成功')
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          // this.equipInfo.lat = Number(this.latlng.lat)
          // 新增设备
          this.$api.equipment
            .addEquip({
              ...this.equipInfo,
              type: Number(this.equipInfo.type),
              lng: Number(this.latlng.lng),
              lat: Number(this.latlng.lat)
            })
            .then((res: any) => {
              this.loading = false
              const data = res.data
              if (data.success) {
                this.$message.success('新增成功')
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      } else {
        return false
      }
    })
  }

  baiduMap(lat?: any, lng?: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this
    const BMapGL = (window as any).BMapGL
    const map = new BMapGL.Map('equipMap')
    // 地图中心点
    let point = new BMapGL.Point(120.626999, 31.332664)
    ;(document.getElementById('latlng') as any).value = '120.626999, 31.332664'
    if (lat && lng) {
      point = new BMapGL.Point(lng, lat)
    }
    map.centerAndZoom(point, 18)
    const marker = new BMapGL.Marker(point)
    marker.addEventListener('click', () => {
      const opts = {
        title: '当前位置' //信息窗口标题
      }
      let addr = '纬度: 120.626999, </br>经度：31.332664'
      if (lat && lng) {
        addr = '纬度: ' + lat + ', </br>' + '经度：' + lng
      }

      const infoWindow = new BMapGL.InfoWindow(addr, opts)
      map.openInfoWindow(infoWindow, point)
    })
    const gc = new BMapGL.Geocoder()
    map.addOverlay(marker)
    map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放

    //marker的enableDragging和disableDragging方法可用来开启和关闭标注的拖拽功能。
    marker.enableDragging()
    //监听标注的dragend事件来捕获拖拽后标注的最新位置
    marker.addEventListener('dragend', (e: any) => {
      ;(document.getElementById('latlng') as any).value =
        e.latLng.lng.toFixed(7) + ', ' + e.latLng.lat.toFixed(7)
      _this.latlng = {
        lat: e.latLng.lat.toFixed(7),
        lng: e.latLng.lng.toFixed(7)
      }
      gc.getLocation(e, () => {
        const opts = {
          title: '当前位置' //信息窗口标题
        }
        const addr =
          '纬度: ' +
          e.latLng.lat.toFixed(7) +
          ',  </br>' +
          '经度：' +
          e.latLng.lng.toFixed(7)
        const infoWindow = new BMapGL.InfoWindow(addr, opts) //创建信息窗口对象
        marker.openInfoWindow(infoWindow)
      })
    })
  }

  // 返回
  goback() {
    this.$router.push('/equipments/equipment-list')
  }

  // 关闭对话框
  closeDialog() {
    this.isShowDialog = false
  }
  // 操作对话框后统一回调
  afterOpt() {
    this.closeDialog()
  }
}
